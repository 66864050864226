var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid online-dashboard-container online-dashboard-content-view",attrs:{"id":"dashboard-content-page"}},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 px-4"},[_c('div',{staticClass:"group-form__header mt-4"},[_c('router-link',{staticClass:"back-button align-items-center mb-3 text-decoration-none link-span d-none d-lg-flex",attrs:{"to":{
            name: 'Playlists'
          }}},[_c('div',{staticClass:"my-auto d-flex back-arrow"},[_c('svg',{staticClass:"px-4 my-auto w-100",attrs:{"width":"16","height":"8","viewBox":"0 0 16 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.146445 3.64645C-0.0488167 3.84171 -0.0488167 4.15829 0.146445 4.35355L3.32843 7.53553C3.52369 7.7308 3.84027 7.7308 4.03553 7.53553C4.23079 7.34027 4.23079 7.02369 4.03553 6.82843L1.20711 4L4.03553 1.17157C4.2308 0.97631 4.2308 0.659727 4.03553 0.464465C3.84027 0.269203 3.52369 0.269203 3.32843 0.464465L0.146445 3.64645ZM15.5 3.5L0.499999 3.5L0.499999 4.5L15.5 4.5L15.5 3.5Z","fill":"#AAA3B1"}})])]),_c('span',{staticClass:"ml-3"},[_vm._v("Back to Playlists")])]),_c('h1',[_vm._v("Social Share")])],1)]),_c('div',{staticClass:"col-12 px-4 mt-5 position-relative tabs-menu"},[_c('nav',{staticClass:"subpage-tabs d-flex"},[_c('router-link',{staticClass:"link-span text-capitalize text-decoration-none",attrs:{"to":{
            name: 'Playlist Sharing Preview',
            params: { id: _vm.playlistId }
          }}},[_vm._v(" Image ")]),_c('router-link',{staticClass:"link-span text-capitalize text-decoration-none",attrs:{"to":{
            name: 'Playlist Sharing List',
            params: { id: _vm.playlistId }
          }}},[_vm._v(" Tracklist ")]),_c('div',{staticClass:"empty-tab"})],1)])]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }